<template>
  <v-card class="mb-6">
    <v-card-title class="d-flex justify-content-between">
      <span> Eventos </span>
      <v-spacer></v-spacer>

      <v-btn color="info" outlined @click="openModal()">
        <v-icon size="30" class="me-2">
          {{ icons.mdiPlaylistPlus }}
        </v-icon>
        Novo
      </v-btn>
    </v-card-title>

    <v-data-table :headers="headers" :items="itemsTable" :loading="loading" loading-text="Carregando dados...">
      <template v-slot:no-data>
        <span>Sem informações para exibir</span>
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ !item.created_at ? '' : dateFormat(item.created_at) }}
      </template>

      <template #[`item.edit`]="{ item }">
        <v-icon medium class="me-2" color="info" @click="openDetails(item)">
          {{ icons.mdiPencil }}
        </v-icon>
      </template>

      <template #[`item.delete`]="{ item }">
        <v-icon medium class="me-2" color="error" @click="deleteRegister(endpointDelete, item.id)">
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="showModal" width="500px">
      <Modal @updatedTable="updatedTable()" @close="showModal = false"></Modal>
    </v-dialog>

    <v-dialog v-model="showDetails" width="500px">
      <Details
        :key="dataDetails.id"
        :data="dataDetails"
        @updatedTable="updatedTable()"
        @close="showDetails = false"
      ></Details>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'
import Details from './Details.vue'
import Modal from './Modal.vue'

export default {
  components: {
    Modal,
    Details,
  },
  mixins: [formatters],

  data() {
    return {
      showModal: false,
      showDetails: false,
      dataDetails: {},

      endpointDelete: 'api/v1/integration/event/destroy',

      headers: [
        {
          text: 'NOME',
          value: 'name',
          sortable: false,
          align: '',
        },
        {
          text: 'CÓDIGO',
          value: 'code',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA DA CRIAÇÃO',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'EDITAR',
          value: 'edit',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DELETAR',
          value: 'delete',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      loading: false,

      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
      },
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.loading = true

      await axiosIns.get('api/v1/integration/event/index').then(response => {
        this.itemsTable = response.data.data
        this.loading = false
      })
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal() {
      this.showModal = true
    },

    openDetails(dataDetails) {
      this.showDetails = true
      this.dataDetails = dataDetails
    },
  },
}
</script>
