<template>
  <v-card>
    <v-card-title class="justify-center mb-2"> Editar </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="data.name"
        label="Nome do evento"
        :append-icon="icons.mdiPencil"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        v-model="data.code"
        label="Código do evento"
        :append-icon="icons.mdiPencil"
        type="number"
        outlined
        dense
      ></v-text-field>

      <div class="d-flex justify-end mt-2">
        <v-btn color="error" @click="closeModal()"> Fechar </v-btn>

        <v-btn color="info" class="ml-5" @click="sendModalData()">
          <span v-if="!loadingCircle">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPencil } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      name: '',
      loadingCircle: false,

      icons: {
        mdiPencil,
      },
    }
  },

  methods: {
    async sendModalData() {
      this.loadingCircle = true

      const body = {
        name: this.data.name.toUpperCase(),
        code: Number(this.data.code),
      }

      await axiosIns
        .put(`api/v1/integration/event/update/${this.data.id}`, body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.loadingCircle = false
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })

      this.updatedTable()
      this.clearData()
      this.closeModal()
    },

    clearData() {
      this.name = ''
    },
  },
}
</script>
