<template>
  <v-card>
    <v-card-title class="justify-center mb-2"> Cadastrar </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-text-field v-model="name" label="Nome do evento" outlined dense></v-text-field>

        <v-text-field v-model="code" label="Código do evento" type="number" outlined dense></v-text-field>
      </v-form>

      <div class="d-flex justify-end mt-2">
        <v-btn color="error" @click="closeModal()"> Cancelar </v-btn>

        <v-btn color="info" class="ml-5" @click="sendModalData()">
          <span v-if="!loadingCircle">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  data() {
    return {
      name: '',
      code: '',

      loadingCircle: false,
    }
  },

  methods: {
    async sendModalData() {
      this.loadingCircle = true

      const body = {
        name: this.name.toUpperCase(),
        code: Number(this.code),
      }

      await axiosIns
        .post('api/v1/integration/event/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.loadingCircle = false
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })

      this.updatedTable()
      this.resetForm()
      this.closeModal()
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
